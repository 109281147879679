import React from 'react';
import { Link } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { Collapse, Button } from 'antd';
import Modal from '../UI/Modal';
import ModalTable from '../CombineModal/ModalTable';
import { formatName } from '../../utils/utils';
import './DemoHome.css';

const { Panel } = Collapse;

export default function DemoHome({ productList }) {
	function onExpiredProductsInstructions() {
		NiceModal.show(Modal, {
			title: 'Find Expired Products Instructions',
			body: (
				<ol>
					<li>Start scanning</li>
					<li>Scan oldest lot of each item</li>
					<ul>
						<li>Scan both parts of 5-gallons</li>
					</ul>
					<li>Stop scanning</li>
					<li>
						Displays results and generates PDF showing all expired products and products expiring in the next three
						months
					</li>
				</ol>
			),
			okButtonText: 'OK',
			cancelGhost: true,
		});
	}
	function onCreateSpreadsheetInsructions() {
		NiceModal.show(Modal, {
			title: 'Find Expired Products Instructions',
			body: (
				<ol>
					<li>Find all expired products</li>
					<li>Gather all expired and Q&D products to return</li>
					<li>Scan all products being returned</li>
					<ul>
						<li>Scan each unit of products</li>
					</ul>
					<li>Stop scanning</li>
					<li>Combine individual parts to full kits</li>
					<li>Add reason for Q&D products</li>
					<li>Download spreadsheet and fill in cost values in highlighted cells</li>
				</ol>
			),
			okButtonText: 'OK',
			cancelGhost: true,
		});
	}

	return (
		<div className="demo-content">
			<Collapse accordion className="collapse">
				<Panel header="Items Scanned for Demo" key="1">
					<ModalTable style={{ width: '100%' }}>
						{productList.map((product) => (
							<tr className="product-row" key={product.id}>
								<td>{product.qty}</td>
								<td>{product.number}</td>
								<td>{formatName(product.name)}</td>
								<td>{product.lotNumber}</td>
							</tr>
						))}
					</ModalTable>
				</Panel>
			</Collapse>
			<div className="results-link-container">
				<span className="results-link">
					<Link to="find-expired-products">Find Expired Products Demo Results</Link>
				</span>
				<Button type="text" onClick={onExpiredProductsInstructions}>
					Instructions
				</Button>
			</div>
			<br />
			<div className="results-link-container">
				<span className="results-link">
					<Link to="create-spreadsheet">Create Spreadsheet Demo Results</Link>
				</span>
				<Button type="text" onClick={onCreateSpreadsheetInsructions}>
					Instructions
				</Button>
			</div>
		</div>
	);
}
