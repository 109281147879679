import React from 'react';
// import TableRow from '../TableRow/tableRow';
import './productTable.css';

export default function ProductTable({ children }) {
	return (
		<table className="product-table">
			<thead className="header-row">
				<tr>
					<th></th>
					<th>Qty</th>
					<th>Part Number</th>
					<th>Product Name</th>
					<th>Lot Number</th>
					<th>
						Age
						<br />
						(months)
					</th>
					<th>
						Remaining
						<br />
						(months)
					</th>
					<th>Reason</th>
				</tr>
			</thead>
			<tbody className="main-table-body">{children}</tbody>
		</table>
	);
}
