import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import DemoHome from '../components/DemoHome/DemoHome';
import ExpiredProducts from '../components/ExpiredProducts/expiredProducts';
import SpreadsheetBuilder from '../components/SpreadsheetBuilder/SpreadsheetBuilder';

const productList = [
	{
		number: '82570A',
		name: 'DRAGON SKIN 10 5-GAL PART-A (40# 18.14KGS)',
		category: 'SILICONES-PLATINUM',
		weight: 40,
		expMonths: 12,
		id: '82570A-2109437',
		lotNumber: '2109437',
		age: 14,
		ageDifference: -2,
		status: 'EXPIRED',
		qty: 2,
		combinable: true,
		combine: false,
	},
	{
		number: '82370B',
		name: 'DRAGON SKIN 10 FAST 5-GAL PART-B(40# 18.14KGS)',
		category: 'SILICONES-PLATINUM',
		weight: 40,
		expMonths: 12,
		id: '82370B-2110836',
		lotNumber: '2110836',
		age: 13,
		ageDifference: -1,
		status: 'EXPIRED',
		qty: 2,
		combinable: true,
		combine: false,
	},
	{
		number: '44461',
		name: 'FEATHER LITE 1-GAL UNIT (11# 4.98KGS)',
		category: 'CASTING RESINS',
		weight: 11,
		expMonths: 18,
		id: '44461-2101264',
		lotNumber: '2101264',
		age: 22,
		ageDifference: -4,
		status: 'EXPIRED',
		qty: 2,
	},
	{
		number: '48861',
		name: 'SMOOTH-CAST 325 1-GAL UNIT(15# 6.80KGS)',
		category: 'CASTING RESINS',
		weight: 15,
		expMonths: 18,
		id: '48861-2107537',
		lotNumber: '2107537',
		age: 16,
		ageDifference: 2,
		expiresOnMonth: '1/2023',
		status: 'EXPIRING SOON',
		qty: 4,
	},
	{
		number: '41370A',
		name: 'FLEX FOAM-IT! 25 5-GAL PART-A(40# 18.14KGS)',
		category: 'EXPANDABLE FOAM',
		weight: 40,
		expMonths: 18,
		id: '41370A-2104482',
		lotNumber: '2104482',
		age: 19,
		ageDifference: -1,
		status: 'EXPIRED',
		qty: 2,
		combinable: true,
		combine: false,
	},
	{
		number: '41370B',
		name: 'FLEX FOAM-IT! 25 5-GAL PART-B (40# 18.14KGS)',
		category: 'EXPANDABLE FOAM',
		weight: 40,
		expMonths: 18,
		id: '41370B-2103853',
		lotNumber: '2103853',
		age: 21,
		ageDifference: -3,
		status: 'EXPIRED',
		qty: 4,
		combinable: true,
		combine: false,
	},
	{
		number: '56461',
		name: 'BODY DOUBLE SILK 1-GAL UNIT (14# 6.36KGS)',
		category: 'SILICONES-PLATINUM',
		weight: 14,
		expMonths: 12,
		id: '56461-2204537',
		lotNumber: '2204537',
		age: 7,
		ageDifference: 5,
		status: 'OKAY',
		qty: 3,
	},
];

const spreadsheetList = productList.map((item) =>
	item.status !== 'EXPIRED' ? { ...item, status: 'Click to add reason' } : item
);

export default function Demo() {
	const navigate = useNavigate();
	function resetHandler() {
		navigate(-1);
	}

	return (
		<Routes>
			<Route index element={<DemoHome productList={productList} />} />
			<Route
				path="find-expired-products"
				element={<ExpiredProducts productList={productList} onReset={resetHandler} />}
			/>
			<Route path="create-spreadsheet" element={<SpreadsheetBuilder productList={spreadsheetList} />} />
		</Routes>
	);
}
