import { Modal } from 'antd';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

export default NiceModal.create((props) => {
	const modal = useModal();

	function onOk() {
		modal.resolve(props.confirm);
		modal.hide();
	}

	return (
		<Modal
			centered
			title={props.title}
			okText={props.okButtonText}
			cancelButtonProps={{
				style: {
					backgroundColor: !props.cancelGhost ? '#ec4758' : '',
					borderColor: '#ec4758',
					color: !props.cancelGhost ? 'white' : '#ec4758',
				},
			}}
			okButtonProps={{
				onClick: onOk,
				style: { backgroundColor: '#1ab394', borderColor: '#1ab394' },
			}}
			onOk={onOk}
			open={modal.visible}
			onCancel={() => {
				modal.resolve(false);
				modal.hide();
			}}
			afterClose={() => modal.remove()}
		>
			<p>{props.body}</p>
		</Modal>
	);
});
