import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import Scanner from '../components/Scanner/scanner';
import SpreadsheetBuilder from '../components/SpreadsheetBuilder/SpreadsheetBuilder';

// RESULTS FROM API
// {
//     "number": "41370B",
//     "name": "FLEX FOAM-IT! 25 5-GAL PART-B (40# 18.14KGS)",
//     "lotNumber": "2009",
//     "category": "EXPANDABLE FOAM",
//     "expMonths": 18,
//     "age": 25,
//     "ageDifference": -7,
//     "status": "EXPIRED"
// }

function formatProductInfo(product) {
	const lastCharacter = product.number.at(-1);
	if (lastCharacter === 'A' || lastCharacter === 'B') {
		return {
			...product,
			// lotNumber: `Part ${lastCharacter} : ${product.lotNumber}`,
			combinable: true,
			combine: false,
			status: product.status === 'EXPIRED' ? 'EXPIRED' : 'Click to add reason',
		};
	}
	return {
		...product,
		status: product.status === 'EXPIRED' ? 'EXPIRED' : 'Click to add reason',
	};
}

function findDuplicate(item, itemToAdd) {
	return item.id === itemToAdd.id;
}

function updateDuplicateListItem(item, itemToAdd) {
	if (item.id === itemToAdd.id) {
		return {
			...item,
			qty: item.qty + 1,
		};
	}
	return item;
}

function updateList(currentList, itemToAdd) {
	// const isDuplicate = _.find(currentList, itemToAdd);
	const isDuplicate = currentList.find((item) => findDuplicate(item, itemToAdd));

	if (isDuplicate) {
		const updatedList = currentList.map((item) => updateDuplicateListItem(item, itemToAdd));
		return updatedList;
	}

	const listWithNewItem = [
		...currentList,
		{
			...itemToAdd,
			qty: 1,
		},
	];

	return listWithNewItem;
}

export default function CreateReturn() {
	const [productList, setProductList] = useState([]);

	// Things it needs to do when adding products
	//
	// check if product is already in list ✅
	//      if so, +1 to quantity
	//      if not, add qty 1 to product and add to list
	// check if individual part ✅
	//      if so, add combinable: true
	//      re-write lot number to be "Lot ${partLetter} : ${lotNumber}"

	function addProduct(scannedProduct) {
		const formattedProduct = formatProductInfo(scannedProduct);
		const updatedList = updateList(productList, formattedProduct);
		setProductList(updatedList);
	}

	// function resetHandler() {
	// 	setProductList([]);
	// }

	return (
		<Routes>
			<Route index element={<Scanner addProduct={addProduct} />} />
			<Route path="results" element={<SpreadsheetBuilder productList={productList} />} />
		</Routes>
	);
}
