import { Route, Routes } from 'react-router-dom';
import './App.css';

import Sidebar from './components/Sidebar/sidebar';
import Home from './pages/Home';
import FindExpiredProducts from './pages/findExpiredProducts';
import CreateReturn from './pages/CreateReturn';
import Demo from './pages/Demo';

function App() {
	return (
		<div className="page-wrapper">
			<Sidebar />
			<main>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/find-expired-products/*" element={<FindExpiredProducts />} />
					<Route path="/create-return/*" element={<CreateReturn />} />
					<Route path="/demo/*" element={<Demo />} />
				</Routes>
			</main>
		</div>
	);
}

export default App;
