import styles from './productInfo.module.css';

export default function ProductInfo({ product }) {
	const { number, name, lotNumber, age, status } = product;

	return (
		<div className={styles.product_info}>
			<div className={styles.product_info__item}>
				<h2>{name}</h2>
				<span>{number}</span>
			</div>
			<div className={styles.product_info__item}>
				<h3>Lot:</h3>
				<span>{lotNumber}</span>
			</div>
			<div className={styles.product_info__item}>
				<h3>Age:</h3>
				<span>{age} months</span>
			</div>
			{status === 'EXPIRED' && (
				<div className={styles.product_info__item}>
					<h3>Over limit by:</h3>
					<span>
						{product.ageDifference === 0
							? 'Just expired this'
							: Math.abs(product.ageDifference)}{' '}
						{Math.abs(product.ageDifference) > 1 ? 'months' : 'month'}
					</span>
				</div>
			)}
			{status === 'EXPIRING SOON' && (
				<div className={styles.product_info__item}>
					<h3>Expires in:</h3>
					<span>
						{product.ageDifference}{' '}
						{product.ageDifference !== 1 ? 'months' : 'month'} (
						{product.expiresOnMonth})
					</span>
				</div>
			)}
		</div>
	);
}
