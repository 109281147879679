import React, { useState } from 'react';
import { Input } from 'antd';
import './tableRow.css';

import { formatName } from '../../utils/utils';

// RESULTS FROM API
// {
//     "number": "41370B",
//     "name": "FLEX FOAM-IT! 25 5-GAL PART-B (40# 18.14KGS)",
//     "lotNumber": "2009",
//     "category": "EXPANDABLE FOAM",
//     "expMonths": 18,
//     "age": 25,
//     "ageDifference": -7,
//     "status": "EXPIRED"
// }

// function checkboxHandler() {
// 	console.log(product);
// 	console.log(setToCombine);
// 	// onSetToCombine(product.id, setToCombine);
// }

function MultiCell({ values }) {
	if (typeof values !== 'object') return values;
	return (
		<table className="inner-table">
			<tbody>
				{values?.map((value, idx) => (
					<tr key={idx}>
						<td>{value}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

export default function TableRow({ product, isCombiningItems, onSetToCombine, onUpdateReason }) {
	const [isInputFocused, setIsInputFocued] = useState(false);
	const [reason, setReason] = useState('');

	function Checkbox({ product, onSetToCombine }) {
		return (
			<input
				type="checkbox"
				onChange={(e) => onSetToCombine(product.id, e.target.checked)}
				checked={product.combine}
				className={!isCombiningItems ? 'hidden' : ''}
				disabled={!isCombiningItems}
				style={{ marginLeft: '5px' }}
			/>
		);
	}

	function inputFocusHandler() {
		setIsInputFocued(true);
	}

	function inputBlurHandler() {
		setIsInputFocued(false);
		if (reason) {
			onUpdateReason(product.id, reason);
		}
	}

	function inputChangeHandler(e) {
		setReason(e.target.value);
	}

	return (
		<tr className={`product-row ${product.combine ? 'product-row-active' : ''}`}>
			<td>{product.combinable ? <Checkbox product={product} onSetToCombine={onSetToCombine} /> : ''}</td>
			<td>{product.qty}</td>
			<td>{product.number}</td>
			<td>{formatName(product.name)}</td>
			<td>
				<MultiCell values={product.lotNumber} />
			</td>
			<td>
				<MultiCell values={product.age} />
			</td>
			<td>
				<MultiCell values={product.ageDifference} />
			</td>
			<td>
				{product.status === 'EXPIRED' ? (
					'EXPIRED'
				) : (
					<Input
						placeholder={product.status}
						bordered={isInputFocused}
						onFocus={inputFocusHandler}
						onBlur={inputBlurHandler}
						onChange={inputChangeHandler}
					/>
				)}
			</td>
		</tr>
	);
}
