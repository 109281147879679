import './scanner.css';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { formatName } from '../../utils/utils';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

export default function Scanner({ addProduct, productList }) {
	const [scanInput, setScanInput] = useState('');
	const [isScanning, setIsScanning] = useState(false);
	const [showFeedback, setShowFeedback] = useState(false);
	const [lastScanned, setLastScanned] = useState('');

	const input = useRef();
	const navigate = useNavigate();

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowFeedback(false);
		}, 1000);

		return () => {
			clearTimeout(timer);
		};
	}, [lastScanned]);

	async function getProduct(product) {
		const scannedItem = product;
		setScanInput('');
		input.current.focus();
		// console.log(`Getting info for ${scannedItem}`);
		try {
			const { data: productInfo } = await axios.get(`${API_ENDPOINT}/api/scan/find-expired-product/`, {
				params: { scannedItem },
			});
			// console.log(productInfo);
			addProduct(productInfo);
			setLastScanned(productInfo);
			setShowFeedback(true);
		} catch (err) {
			console.error(err);
		}
	}

	function onStartScanning() {
		setIsScanning(true);
		input.current.focus();
	}

	function onStopScanning() {
		setIsScanning(false);
		navigate('results');
	}

	// function formatName(productName) {
	// 	if (!productName) return 'Product not Found';
	// 	if (productName.indexOf('(') !== -1) {
	// 		return productName.split('(')[0];
	// 	}
	// 	return productName;
	// }

	return (
		<section className="scanner">
			<div className={`scanning-text ${isScanning ? '' : 'hidden'}`}>Scanning in progress</div>

			<div className={`search`}>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						getProduct(scanInput);
					}}
				>
					<label>Find Product</label>
					<input
						ref={input}
						type="text"
						name="id"
						value={scanInput}
						onChange={(e) => {
							setScanInput(e.target.value);
						}}
						onBlur={() => input.current.focus()}
					/>
					<button type="submit"> Find Product </button>
				</form>
			</div>

			<section className="scan-buttons">
				<button className="btn-green start" disabled={isScanning} onClick={onStartScanning}>
					Start Scanning
				</button>
				<button className="btn-red stop" disabled={!isScanning} onClick={onStopScanning}>
					Stop Scanning
				</button>
			</section>

			<div className={`scan-feedback ${showFeedback ? '' : 'hidden'}`}>Scanned {formatName(lastScanned.name)}</div>
		</section>
	);
}
