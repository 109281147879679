import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import './sidebar.css';

export default function Sidebar() {
	return (
		<nav className="sidebar">
			<div className="sidebar-image">
				<Link to="/">
					<img src="/lightning-bolt-100.png" width="100px" alt="Home" />
				</Link>
			</div>
			<ul className="nav-items">
				<li>
					<NavLink to="/find-expired-products" className={({ isActive }) => (isActive ? 'nav-active' : undefined)}>
						<div className="nav-item">Find Expired Products</div>
					</NavLink>
				</li>
				<li>
					<NavLink to="/create-return" className={({ isActive }) => (isActive ? 'nav-active' : undefined)}>
						<div className="nav-item">Create Return Spreadsheet</div>
					</NavLink>
				</li>
			</ul>
		</nav>
	);
}
