import React from 'react';
import { Link } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { Button } from 'antd';
import Modal from '../components/UI/Modal';

import './home.css';

export default function Home() {
	function onExpiredProductsInstructions() {
		NiceModal.show(Modal, {
			title: 'Find Expired Products Instructions',
			body: (
				<ol>
					<li>Start scanning</li>
					<li>Scan oldest lot of each item</li>
					<ul>
						<li>Scan both parts of 5-gallons</li>
					</ul>
					<li>Stop scanning</li>
					<li>
						Displays results and generates PDF showing all expired products and products expiring in the next three
						months
					</li>
				</ol>
			),
			okButtonText: 'OK',
			cancelGhost: true,
		});
	}
	function onCreateSpreadsheetInsructions() {
		NiceModal.show(Modal, {
			title: 'Find Expired Products Instructions',
			body: (
				<ol>
					<li>Find all expired products</li>
					<li>Gather all expired and Q&D products to return</li>
					<li>Scan all products being returned</li>
					<ul>
						<li>Scan each unit of products</li>
					</ul>
					<li>Stop scanning</li>
					<li>Combine individual parts to full kits</li>
					<li>Add reason for Q&D products</li>
					<li>Download spreadsheet and fill in cost values in highlighted cells</li>
				</ol>
			),
			okButtonText: 'OK',
			cancelGhost: true,
		});
	}

	return (
		<section className="home-content">
			<Link to="/find-expired-products">Find Expired Products</Link>
			<Button type="text" onClick={onExpiredProductsInstructions}>
				Instructions
			</Button>
			<Link to="/create-return">Create Return Spreadsheet</Link>
			<Button type="text" onClick={onCreateSpreadsheetInsructions}>
				Instructions
			</Button>
		</section>
	);
}
