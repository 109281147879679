import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import 'antd/dist/antd.css';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<NiceModal.Provider>
				<App />
			</NiceModal.Provider>
		</BrowserRouter>
	</React.StrictMode>
);
