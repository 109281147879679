import { Routes, Route, useNavigate } from 'react-router-dom';
import Scanner from '../components/Scanner/scanner';
import ExpiredProducts from '../components/ExpiredProducts/expiredProducts';
import { useState } from 'react';

export default function FindExpiredProducts() {
	const [productList, setProductList] = useState([]);
	const navigate = useNavigate();

	function addProduct(scannedProduct) {
		const isDuplicate = productList.find((item) => item.id === scannedProduct.id);

		if (!isDuplicate) {
			setProductList((prevList) => [...prevList, scannedProduct]);
		}
	}

	function resetHandler() {
		setProductList([]);
		navigate('/find-expired-products');
	}

	return (
		<Routes>
			<Route index element={<Scanner addProduct={addProduct} />} />
			<Route path="results" element={<ExpiredProducts productList={productList} onReset={resetHandler} />} />
		</Routes>
	);
}
