import React from 'react';

export default function ModalTable({ children, style }) {
	return (
		<table className="product-table" style={style}>
			<thead className="header-row">
				<tr>
					<th>Qty</th>
					<th>Part Number</th>
					<th>Product Name</th>
					<th>Lot Number</th>
				</tr>
			</thead>
			<tbody className="main-table-body">{children}</tbody>
		</table>
	);
}
