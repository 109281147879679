import React, { useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import fileDownload from 'js-file-download';

import ProductTable from '../ProductTable/productTable';
import TableRow from '../TableRow/tableRow';
import CombineModal from '../CombineModal/CombineModal';
import Modal from '../UI/Modal';

import './SpreadsheetBuilder.css';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';
// RESULTS FROM API
// {
//     "number": "41370B",
//     "name": "FLEX FOAM-IT! 25 5-GAL PART-B (40# 18.14KGS)",
//     "lotNumber": "2009",
//     "category": "EXPANDABLE FOAM",
//     "expMonths": 18,
//     "age": 25,
//     "ageDifference": -7,
//     "status": "EXPIRED"
// }

function onUpdateRemainders(currentList, remainders) {
	if (remainders) {
		const updatedList = currentList.map((item) => {
			const remainder = remainders.find((remainder) => remainder.id === item.id);
			if (remainder) {
				return {
					...item,
					qty: remainder.qty,
					combine: false,
				};
			}
			return item;
		});

		return updatedList;
	}
	return currentList;
}

function setItemCombineState(item, itemToUpdate, combineValue) {
	if (item.id === itemToUpdate) {
		return {
			...item,
			combine: combineValue,
		};
	}
	return item;
}

function clearItemCombineState(item) {
	return {
		...item,
		combine: false,
	};
}

function formatLots(product) {
	const lastCharacter = product.number.at(-1);
	if (lastCharacter === 'A' || lastCharacter === 'B') {
		return `Part ${lastCharacter} : ${product.lotNumber}`;
	}
	return product.lotNumber;
}

function getPartQuantities(items) {
	const aQty = items.filter((item) => item.number.at(-1) === 'A').reduce((sum, cur) => sum + cur.qty, 0);
	const bQty = items.filter((item) => item.number.at(-1) === 'B').reduce((sum, cur) => sum + cur.qty, 0);
	const partAs = {
		qty: aQty,
	};
	const partBs = {
		qty: bQty,
	};
	return [partAs, partBs];
}

function sortByQty(items) {
	return items.sort((a, b) => (a.qty > b.qty ? -1 : 1));
}

function checkPartRatio(itemsToCombine) {
	const partQtys = getPartQuantities(itemsToCombine);
	const sortByPartQty = sortByQty(partQtys);
	return sortByPartQty[0].qty / sortByPartQty[1].qty;
}

function processItemsToCombine(itemsToCombine) {
	const sortByPartQty = sortByQty(itemsToCombine);
	if (sortByPartQty.length === 2 && checkPartRatio(sortByPartQty) === 2) {
		const [twoParts, onePart] = sortByPartQty;
		const twoToOne = [twoParts, twoParts, onePart].sort((a, b) => (a.number.at(-1) < b.number.at(-1) ? -1 : 1));

		const lots = twoToOne.map((item) => formatLots(item));
		const ages = twoToOne.map((item) => item.age);
		const ageDifferences = twoToOne.map((item) => item.ageDifference);

		return [lots, ages, ageDifferences];
	}
	const lots = itemsToCombine.map((item) => formatLots(item));
	const ages = itemsToCombine.map((item) => item.age);
	const ageDifferences = itemsToCombine.map((item) => item.ageDifference);

	return [lots, ages, ageDifferences];
}

// takes items to combine and full kit into and combines it to one
function mergeItemsWithFullKit(itemsToCombine, fullKit) {
	const qty = itemsToCombine.map((item) => item.qty).reduce((lowest, current) => (current < lowest ? current : lowest));
	const [lotNumber, age, ageDifference] = processItemsToCombine(itemsToCombine);

	// every part expired = set to expired
	const status = itemsToCombine.some((item) => item.status === 'EXPIRED') ? 'EXPIRED' : 'Click to add reason';
	const id = `${lotNumber.join('')}`;
	return {
		...fullKit,
		id,
		qty,
		lotNumber,
		age,
		ageDifference,
		status,
	};
}

export default function SpreadsheetBuilder({ productList: initialList }) {
	const [productList, setProductList] = useState(initialList);
	const [isCombiningItems, setIsCombiningItems] = useState(false);

	const itemsToCombine = productList.filter((item) => item.combine);

	const canCombine = itemsToCombine.length >= 2;

	async function downloadSpreadsheet() {
		try {
			const response = await axios({
				url: `${API_ENDPOINT}/api/report/generate-spreadsheet`,
				method: 'post',
				responseType: 'blob',
				data: { productList },
			});
			fileDownload(response.data, 'return-spreadsheet.xlsx');
		} catch (err) {
			console.error(err);
		}
	}

	function clearCombineList() {
		const clearedCombineState = productList.map((item) => clearItemCombineState(item));
		setProductList(clearedCombineState);
	}

	function toggleCombineMode() {
		setIsCombiningItems((prevState) => !prevState);
		clearCombineList();
	}

	function turnOnItemCombineState(itemID, combineValue) {
		const updatedList = productList.map((item) => setItemCombineState(item, itemID, combineValue));
		setProductList(updatedList);
	}

	async function onCombineItems() {
		const results = await NiceModal.show(CombineModal, {
			itemsToCombine,
		});
		if (results) {
			const [rawItemsToCombine, fullKit, remainders] = results;
			const itemsBeingCombined = rawItemsToCombine.filter((item) => item.qty !== 0);
			const ratio = checkPartRatio(itemsBeingCombined);
			const [partA, partB] = getPartQuantities(itemsBeingCombined);
			const totalParts = partA.qty + partB.qty;

			if (ratio === 2 && itemsBeingCombined.length > 2 && totalParts !== 3) {
				return NiceModal.show(Modal, {
					title: 'Are you sure?',
					body: (
						<>
							This would create full kits with lot numbers that do not match. Try creating each combination separately.
						</>
					),
					okButtonText: 'OK',
					cancelGhost: true,
				});
			}
			if (ratio !== 2 && ratio !== 1) {
				if (itemsBeingCombined.length >= 2 && totalParts !== 2) {
					const confirm = await NiceModal.show(Modal, {
						title: 'Are you sure?',
						body: (
							<>
								{`This would be a ${partA.qty}A:${partB.qty}B ratio.`}
								<br />
								It may not combine correctly.
							</>
						),
						okButtonText: 'Confirm',

						confirm: true,
					});
					if (!confirm) {
						return onCombineItems();
					}
				}
			}

			const combinedItemInfo = mergeItemsWithFullKit(itemsBeingCombined, fullKit);

			const updateRemainders = onUpdateRemainders(productList, remainders);
			const filterOutCombinedItems = updateRemainders.filter((item) => !item.combine);
			const newList = [...filterOutCombinedItems, combinedItemInfo];

			setProductList(newList);
		}
	}

	function resetHandler() {
		setProductList(initialList);
		setIsCombiningItems(false);
	}

	function onUpdateReason(idToUpdate, reason) {
		const updatedList = productList.map((item) => {
			if (item.id === idToUpdate) {
				return {
					...item,
					status: reason,
				};
			}
			return item;
		});
		setProductList(updatedList);
	}

	return (
		<section className="spreadsheet-builder">
			{/* <h1>SpreadsheetBuilder</h1> */}

			<Tooltip title="Download Spreadsheet" trigger="hover">
				<button className="btn-download" onClick={downloadSpreadsheet}>
					<DownloadOutlined style={{ fontSize: '32px' }} />
				</button>
			</Tooltip>
			<div className="combine-btn-container">
				<button className={`btn-pill ${isCombiningItems ? 'btn-red' : 'btn-green'}`} onClick={toggleCombineMode}>
					{!isCombiningItems ? 'Combine Items' : 'Stop Combining'}
				</button>
				<Tooltip title="Select two or more items to combine" trigger="hover">
					{isCombiningItems && (
						<button
							className={`btn-pill ${canCombine ? 'btn-green' : 'combine-disabled'}`}
							onClick={() => canCombine && onCombineItems()}
						>
							Combine Items
						</button>
					)}
				</Tooltip>
			</div>

			<ProductTable>
				{productList.map((item) => (
					<TableRow
						product={item}
						isCombiningItems={isCombiningItems}
						onSetToCombine={turnOnItemCombineState}
						onUpdateReason={onUpdateReason}
						key={item.id}
					/>
				))}
			</ProductTable>

			<button className="btn-pill btn-red btn-reset" onClick={resetHandler} title="lets see if this works">
				Reset
			</button>
		</section>
	);
}
