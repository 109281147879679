import { useEffect, useState, useRef } from 'react';
// import 'antd/dist/antd.css';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal, Tooltip, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
// import 'antd/lib/modal/style/css';
import axios from 'axios';

import './CombineModal.css';

import ModalTable from './ModalTable';
import ModalTableRow from './ModalTableRow';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

async function fetchInfo(fullKitGuess) {
	if (fullKitGuess) {
		try {
			const { data: productInfo } = await axios.get(`${API_ENDPOINT}/api/products/${fullKitGuess}`);
			return productInfo;
		} catch (err) {
			console.error(err);
		}
	}
}

export default NiceModal.create(({ itemsToCombine: initialList }) => {
	const [itemsToCombine, setItemsToCombine] = useState(initialList);
	const [input, setInput] = useState('');
	const [fullKit, setFullKit] = useState(null);
	const [results, setResults] = useState(null);
	const [errorMsg, setErrorMsg] = useState('');
	const remaindersRef = useRef([]);

	useEffect(() => {
		setItemsToCombine(initialList.sort((a, b) => (a.number.at(-1) < b.number.at(-1) ? -1 : 1)));
		const checkPartNumbers = new Set(initialList.map((item) => item.number.slice(0, -2)));
		if (checkPartNumbers.size === 1) {
			const [first] = checkPartNumbers;
			const partNumber = `${first}1`;
			findItem(partNumber);
		}
	}, []);

	async function findItem(partNumber) {
		const productFound = await fetchInfo(partNumber);
		if (productFound) {
			setFullKit(productFound);
			setResults([itemsToCombine, productFound, remaindersRef.current]);
		}
		if (partNumber === '') return setErrorMsg('Please enter a part number');
		if (!productFound) setErrorMsg('Part Number not found');
	}

	async function search(e) {
		e.preventDefault();
		findItem(input);
		setInput('');
	}

	function clearGuess() {
		setFullKit(null);
		// take out below when submit disabled without fullKit
		// setResults([itemsToCombine, fullKit, remaindersRef.current]);
	}

	function onQuantityChange(id, quantityToCombine) {
		const updatedList = itemsToCombine.map((item) => {
			if (item.id === id) {
				return {
					...item,
					qty: Number(quantityToCombine),
				};
			}
			return item;
		});

		setItemsToCombine(updatedList);

		remaindersRef.current = initialList
			.map((item, idx) => {
				return {
					...item,
					qty: item.qty - updatedList[idx].qty,
				};
			})
			.filter((item) => item.qty !== 0);
		setResults([updatedList, fullKit, remaindersRef.current]);
	}

	function onCancel() {
		modal.hide();
	}

	function onOk() {
		modal.resolve(results);
		modal.hide();
		modal.remove();
	}

	// Use a hook to manage the modal state
	const modal = useModal();
	return (
		<Modal
			title="Combine Items"
			open={modal.visible}
			onCancel={onCancel}
			afterClose={() => modal.remove()}
			cancelButtonProps={{ style: { backgroundColor: '#ec4758', borderColor: '#ec4758', color: 'white' } }}
			okButtonProps={{
				onClick: onOk,
				disabled: !fullKit,
				style: { backgroundColor: '#1ab394', borderColor: '#1ab394' },
			}}
			// footer={[
			// 	<div className="modal-footer">
			// 		<Button style={{ backgroundColor: '#ec4758', borderColor: '#ec4758', color: 'white' }}>Cancel</Button>
			// 		{/* <button onClick={onCancel}>Cancel</button> */}
			// 		<Tooltip title="Enter a full kit to combine." trigger={!fullKit ? 'hover' : ''} placement="topRight">
			// 			{/* <button onClick={onOk} disabled={!fullKit}>
			// 				OK
			// 			</button> */}
			// 			<Button
			// 				style={{ backgroundColor: '#1ab394', borderColor: '#1ab394' }}
			// 				onClick={onOk}
			// 				disabled={!fullKit}
			// 			></Button>
			// 		</Tooltip>
			// 	</div>,
			// ]}
		>
			<ModalTable>
				{initialList.map((item) => (
					<ModalTableRow product={item} key={item.id} onChangeQty={onQuantityChange} />
				))}
			</ModalTable>
			{fullKit && (
				<>
					<h2>Combine to</h2>
					<h3>{fullKit.name}?</h3>
					<Button type="text" onClick={clearGuess} style={{ padding: '0' }}>
						Not correct? Click here
					</Button>
				</>
			)}
			{!fullKit && (
				<>
					<h2>Enter Full Kit Part Number:</h2>
					<form onSubmit={search} className="fullKit-search-form">
						<input type="text" value={input} onChange={(e) => setInput(e.target.value)} />
						<Button
							type="submit"
							onClick={search}
							style={{
								backgroundColor: '#1ab394',
								borderColor: '#1ab394',
								color: 'white',
								height: '28px',
								borderRadius: '0 2px 2px 0',
							}}
						>
							<SearchOutlined />
						</Button>
					</form>
					{errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>}
				</>
			)}
		</Modal>
	);
});
