import { useState } from 'react';

import { formatName } from '../../utils/utils';

export default function ModalTableRow({ product, onChangeQty }) {
	const [quantity, setQuantity] = useState(product.qty);
	// const qtyRef = useRef(product.qty);

	// useEffect(() => {
	// 	onChangeQty(product.id, quantity);
	// }, [quantity]);

	function qtyChangeHandler(e) {
		onChangeQty(product.id, e.target.value);
		setQuantity(e.target.value);
		// console.log(e.target.value);
		// 	console.log(e.target);
	}

	return (
		<tr className="product-row">
			<td>
				<input type="number" min={0} max={product.qty} value={quantity} onChange={qtyChangeHandler} />
			</td>
			<td>{product.number}</td>
			<td>{formatName(product.name)}</td>
			<td>{product.lotNumber}</td>
		</tr>
	);
}
